import React from 'react';
import { Page, SEO, Share, SeparatorLine } from '../components';
import {
  MainNavigation,
  Intro,
  NewsSection,
  Section02,
  Section03,
  Section04,
  Section05,
  Section06,
  Section07,
  Sponsors,
  Footer,
} from '../compositions';

const IndexPage = () => {
  return (
    <Page>
      <SEO title="Virtual Refugee Conference" />
      <Share />
      <MainNavigation />
      <Intro />
      <Sponsors />
      <Section03 />
      <Section02 />
      <Section04 />
      <NewsSection />
      <Section05 />
      <Section06 />
      <Section07 />
      <Footer />
      <SeparatorLine />
    </Page>
  );
};

export default IndexPage;
